import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/dialog/accessibility",
  "title": "Dialog - Accessibility"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "pay-attention-to",
      "style": {
        "position": "relative"
      }
    }}>{`Pay attention to`}<a parentName="h3" {...{
        "href": "#pay-attention-to",
        "aria-label": "pay attention to permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`When the dialog is closed, the focus should return to the element that triggered the original dialog context. This allows keyboard and screen reader users to continue the context where they originally opened the dialog.`}
        <ul parentName="li">
          <li parentName="ul">{`If the dialog was opened on a page load, the focus should be returned to the start of the `}<inlineCode parentName="li">{`body`}</inlineCode>{` element.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`When the dialog is active, everything outside of the dialog should be inaccessible to the user.`}</li>
      <li parentName="ul">{`Pay attention to the roles of buttons of your dialog. If you use the button as a link instead of an action (i.e. the button press opens a new page), you must specify a `}<inlineCode parentName="li">{`role="link"`}</inlineCode>{` attribute to the button.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      